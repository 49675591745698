
$(document).ready(function() {


    $("#tost").click(function(e) {
        
        e.preventDefault();
        var form = document.getElementById('form')
        var nameEl = document.getElementById('name')
        var name = $("#name").val();
        var surname = $("#surname").val();
        var company = $("#company").val();
        var email = $("#email").val();
        var phone = $("#phone").val();
        var profession = $("#profession").val();
        var Webpages = $("#Webpages").is(":checked")
        var Eshop = $("#Eshop").is(":checked")
        var CustomWebsites = $("#CustomWebsites").is(":checked")
        var SocialMedia = $("#SocialMedia").is(":checked")
        var Storytelling = $("#Storytelling").is(":checked")
        var leadGen = $("#leadGen").is(":checked")
        var googleAds = $("#googleAds").is(":checked")
        var Sales = $("#Sales").is(":checked")
        var emailMarketing = $("#emailMarketing").is(":checked")
        var Blog = $("#Blog").is(":checked")
        var Doctor = $("#Doctor").is(":checked")
        var Branding = $("#Branding").is(":checked")
        var Identity = $("#Identity").is(":checked")
        var Photo = $("#Photo").is(":checked")
        var Video = $("#Video").is(":checked")
        var SEO = $("#SEO").is(":checked")

        ///////////////////////
        // create custom events
        const nameRequired = new CustomEvent('requiredField', {detail: {field: 'name'}});
        const surnameRequired = new CustomEvent('requiredField', {detail: {field: 'surname'}});
        const emailRequired = new CustomEvent('requiredField', {detail: {field: 'email'}});
        const phoneRequired = new CustomEvent('requiredField', {detail: {field: 'phone'}});
        const companyRequired = new CustomEvent('requiredField', {detail: {field: 'company'}});
        const professionRequired = new CustomEvent('requiredField', {detail: {field: 'profession'}});

        document.addEventListener('requiredField', function (e) { 
            $('#'+e.detail.field).addClass("form_fill");
            $('#'+e.detail.field+'Label').addClass("form_error");
        }, false);

        if (name == '' || surname == '' || email == '' || phone == '' || company == '' || profession == '') {
            if (!name) document.dispatchEvent(nameRequired);
            if (!surname) document.dispatchEvent(surnameRequired);
            if (!email) document.dispatchEvent(emailRequired);
            if (!phone) document.dispatchEvent(phoneRequired);
            if (!company) document.dispatchEvent(companyRequired);
            if (!profession) document.dispatchEvent(professionRequired);

            const positionFromTopOfScrollableDiv = nameEl.offsetTop
            form.scrollTop = positionFromTopOfScrollableDiv
        } 
        else {
            grecaptcha.execute('6Ldl03EeAAAAAJx61DDRZQEGy3LuRIjdDilDV8Ti', {action: 'submit'})
            .then( token => {
                $.ajax({
                    type: 'POST',
                    url:  'https://agency.doitforme.support/backend/emails/sendEmail',
                    data: {
                        name:name, 
                        surname:surname, 
                        company:company, 
                        email:email, 
                        phone:phone, 
                        profession:profession, 
                        Webpages:Webpages, 
                        Eshop:Eshop, 
                        CustomWebsites:CustomWebsites, 
                        SocialMedia:SocialMedia, 
                        Storytelling:Storytelling,
                        leadGen:leadGen, 
                        googleAds:googleAds, 
                        Sales:Sales, 
                        emailMarketing:emailMarketing, 
                        SEO:SEO,
                        Blog: Blog,
                        Doctor: Doctor,
                        Branding: Branding,
                        Identity: Identity,
                        Photo: Photo,
                        Video: Video,
                        title: 'Φόρμα επικοινωνίας Agency',
                        template: 'interested_form',
                        captchaToken: token,
                        domain: 'agency.doitforme.support'
                    },
                    success: function () {
                        console.log('Sending...')
    
                        $("#toggler1").trigger("click");
                        $('#form')[0].reset();
                    },
                    error: function (xhr, status, error) {
                        console.log('Error: ' + error);
                    }
                });
            }).catch(err => console.log(err))
        }
    });

    ////////////////////////////////////////////////
    // Toggler buttons for contact form show/hide //
    ////////////////////////////////////////////////
    $("#toggler1").on('click', function() {
        if($("#form").hasClass("selected")) $("#form").removeClass("selected");
        else $("#form").addClass("selected");    
    })
    $("#toggler2").on('click', function() {
        if($("#form").hasClass("selected")) $("#form").removeClass("selected");
        else $("#form").addClass("selected");    
    })
    $("#toggler3").on('click', function(e) {
        e.preventDefault();
        if($("#form").hasClass("selected")) $("#form").removeClass("selected");
        else $("#form").addClass("selected");    
    })

    ////////////////////////////////////////////////////////
    // When user types, red borders (errors) must go away //
    ////////////////////////////////////////////////////////
    $("#name").keyup(function() {
        if($("#name").hasClass("form_fill")) $("#name").removeClass("form_fill");
        if($("#nameLabel").hasClass("form_error")) $("#nameLabel").removeClass("form_error");
    });
    $("#surname").keyup(function() {
        if($("#surname").hasClass("form_fill")) $("#surname").removeClass("form_fill");
        if($("#surnameLabel").hasClass("form_error")) $("#surnameLabel").removeClass("form_error");
    });
    $("#email").keyup(function() {
        if($("#email").hasClass("form_fill")) $("#email").removeClass("form_fill");
        if($("#emailLabel").hasClass("form_error")) $("#emailLabel").removeClass("form_error");
    });
    $("#phone").keyup(function() {
        if($("#phone").hasClass("form_fill")) $("#phone").removeClass("form_fill");
        if($("#phoneLabel").hasClass("form_error")) $("#phoneLabel").removeClass("form_error");
    });
    $("#company").keyup(function() {
        if($("#company").hasClass("form_fill")) $("#company").removeClass("form_fill");
        if($("#companyLabel").hasClass("form_error")) $("#companyLabel").removeClass("form_error");
    });
    $("#profession").keyup(function() {
        if($("#profession").hasClass("form_fill")) $("#profession").removeClass("form_fill");
        if($("#professionLabel").hasClass("form_error")) $("#professionLabel").removeClass("form_error");
    });
});